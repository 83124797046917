import { DateTime } from 'luxon';
import type { ReactNode } from 'react';
import { TimeZoneDisplay } from '@/types/gql.generated';
import { useCreateDecoratedExperienceEvents } from '../../../events/hooks/useCreateDecoratedExperienceEvents';
import { PageExperienceContext } from './PageExperienceContext';
import type { PageExperienceContextValue } from './types';

type Props = {
  children: ReactNode;
  readOnly?: boolean;
  experienceQuery: PageExperienceContextValue['experienceQuery'];
  eventsQuery: PageExperienceContextValue['eventsQuery'];
};

export const PageExperienceContextProvider = ({
  children,
  readOnly = false,
  experienceQuery,
  eventsQuery,
}: Props) => {
  const experience = experienceQuery?.data;

  const authorTimezone =
    experienceQuery?.data?.timeZone ?? DateTime.local().zoneName;

  const displayTimezone =
    experience?.timeZoneDisplay === TimeZoneDisplay.Experience
      ? authorTimezone
      : DateTime.local().zoneName;

  const { decoratedExperienceEvents } = useCreateDecoratedExperienceEvents(
    experienceQuery?.data,
    eventsQuery?.data,
    displayTimezone
  );

  const value: PageExperienceContextValue = {
    experienceQuery: experienceQuery,
    experience,
    authorTimezone,
    displayTimezone,
    eventsQuery,
    events: decoratedExperienceEvents,
    readOnly,
    isPreview: false,
  };

  return (
    <PageExperienceContext.Provider value={value}>
      {children}
    </PageExperienceContext.Provider>
  );
};
